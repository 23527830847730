.locale-ru {
    .schema {
        .computer {
            table {
                left: 1.1em;
            }
        }

        .code-box {
            width: 39em;

            .code-arrow.code-arrow-1, .code-arrow.code-arrow-2, .code-arrow.code-arrow-3 {
                left: 15.5em;
            }
        }

        .separator {
            width: 0.9em;
        }

        @media (min-width: 992px) {
            .arrow-2 {
                left: 15.75em;
            }
        }

        .server {
            &:before {
                content: "Сервер";
                right: 0.9em;
            }
        }

    }

}

.schema {
    justify-content: space-between;
    display: flex;
    align-items: center;
    flex-direction: column;

    .computer-mini {
        height: fit-content;
        width: 14em;
        margin-bottom: 1em;
        margin-top: 1em;
        position: relative;

        img {
            width: 100%;
            height: 100%;
        }

        ul {
            position: absolute;
            top: 1.5em;
            list-style-type: none;
            left: 3.2em;
            font-size: 1.1em;

            li:nth-child(1), li:nth-child(2) {
                background-color: rgba(50, 116, 197, 0.25)
            }

            li:nth-child(4) {
                background-color: #74c53240;
            }
        }
    }

    .computer {
        display: none;
        height: fit-content;
        width: 14em;
        margin-bottom: 1em;
        margin-top: 1em;
        position: relative;

        img {
            width: 100%;
            height: 100%;
        }

        table {
            position: absolute;
            top: 0.8em;
            left: 1.85em;
        }

        td {
            border: 1px solid;
            text-align: center;
            padding: 0.3em 0.4em 0.4em;
        }

        tr:nth-child(2) td:nth-child(2) {
            background-color: #74c53240
        }

        ul {
            list-style-type: none;
            position: absolute;
            top: 6em;
            line-height: 1.2em;
            left: 3.4em;

            li:nth-child(1), li:nth-child(2) {
                background-color: rgba(50, 116, 197, 0.25)
            }
        }
    }

    .arrow {
        width: 2em;

        svg {
            full: #008901;
        }
    }

    .arrow-1 {
        transform: rotate(-90deg);
    }

    .arrow-2 {
        transform: rotate(90deg);
    }

    .separator {
        width: 1em;
    }

    .code-box-mini {
        position: relative;
        display: flex;
        font-size: 0.7em;

        .code-arrow {
            position: absolute;
            width: 8em;
        }

        .code-arrow.code-arrow-1 {
            width: 1.5em;
            left: 50%;
            transform: translate(-100%);
            top: 3em;
        }

        .code-arrow.code-arrow-2 {
            width: 1.5em;
            left: 50%;
            transform: rotate(180deg);
            top: 3em;
        }

        .code-arrow.code-arrow-3 {
            width: 0.8em;
            left: 50%;
            transform: translate(-100%);
            top: 7.6em;
        }

        .code-arrow.code-arrow-4 {
            width: 0.8em;
            left: 50%;
            top: 7.6em;
            transform: rotate(180deg);
        }
    }

    .code-box {
        display: none;
        position: relative;

        .code-arrow {
            position: absolute;
            width: 8em;
        }

        .code-arrow.code-arrow-1 {
            top: 13.8em;
            left: 15em;
        }

        .code-arrow.code-arrow-2 {
            width: 8em;
            top: 20.6em;
            left: 15em;
            transform: rotate(10deg);
        }

        .code-arrow.code-arrow-3 {
            top: 20.6em;
            left: 15em;
            transform: rotate(-10deg);
        }
    }

    .server {
        position: relative;
        margin-top: 2.5em;

        img {
            height: fit-content;
            width: 9em;
        }

        &:before {
            top: -1.6em;
            right: 1.2em;
            font-size: 1.7em;
            font-weight: 600;
            position: absolute;
            content: "Server";
        }
    }


    @media (min-width: 400px) {
        .code-box-mini {
            font-size: 0.8em;
        }
    }
    @media (min-width: 500px) {
        .code-box-mini {
            font-size: 1em;
        }
    }

    @media (min-width: 768px) {
        .computer-mini {
            display: none;
        }
        .computer {
            display: initial;
        }
        .code-box-mini {
            display: none;
        }
        .code-box {
            display: flex;
        }

    }

    @media (min-width: 992px) {
        flex-direction: row;
        align-items: start;
        .arrow-1 {
            transform: rotate(-180deg);
            position: relative;
            top: 4.5em;
        }
        .arrow-2 {
            position: absolute;
            left: 16.25em;
            transform: rotate(180deg);
            top: 26em;
        }
        .server {
            position: absolute;
            top: 21em;
            left: 5.7em;
        }

    }

    @media (min-width: 1200px) {
        align-items: center;
        .arrow-1 {
            transform: rotate(-180deg);
            position: initial;
        }
        .arrow-2 {
            position: initial;
            transform: rotate(0deg);
        }
        .server {
            position: relative;
            top: unset;
            left: unset;
            margin: unset;
        }
    }


}
