@charset "UTF-8";
.btn-basis {
  color: #fff;
  background-color: #336fa1;
  border-color: #336fa1;
}

.btn-basis:active, .btn-basis:hover {
  color: #fff;
  background-color: #306897;
  border-color: #306897;
}

.medium_block_image {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 15px;
}

.flex-equal > * {
  flex: 1;
}

@media (min-width: 576px) {
  .bd-example {
    padding: 1.5rem;
    margin-right: 0;
    margin-left: 0;
    border-width: 1px;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
}
@media (min-width: 768px) {
  .flex-md-equal > * {
    flex: 1;
  }
}
.bd-example {
  position: relative;
  padding: 0;
  margin: 1rem -0.75rem 0;
  border: solid #dee2e6;
  border-width: 1px;
  background: white;
  height: 20em;
  overflow-x: auto;
  overflow-y: hidden;
}

#home-table .bd-example table td:nth-child(2),
#home-table .bd-example table th:nth-child(2) {
  display: none;
}

#home-table .bd-example table tr:nth-child(6) {
  display: none;
}

@media (min-width: 1200px) {
  #home-table .bd-example table td, #home-table .bd-example table th {
    font-size: unset;
  }
  #home-table .bd-example table td:nth-child(2),
#home-table .bd-example table th:nth-child(2) {
    display: table-cell;
  }
  #home-table .bd-example table tr:nth-child(6) {
    display: table-row;
  }
  #home-table .bd-example table tr:nth-child(7) {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .bd-example .table > :not(caption) > * > * {
    padding: 0.4rem 0.4rem;
  }
}
@media (min-width: 1400px) {
  #home-table .bd-example table td, #home-table .bd-example table th {
    padding: 0.5rem 0.5rem;
    font-size: unset;
  }
}
.locale-ru .schema .computer table {
  left: 1.1em;
}
.locale-ru .schema .code-box {
  width: 39em;
}
.locale-ru .schema .code-box .code-arrow.code-arrow-1, .locale-ru .schema .code-box .code-arrow.code-arrow-2, .locale-ru .schema .code-box .code-arrow.code-arrow-3 {
  left: 15.5em;
}
.locale-ru .schema .separator {
  width: 0.9em;
}
@media (min-width: 992px) {
  .locale-ru .schema .arrow-2 {
    left: 15.75em;
  }
}
.locale-ru .schema .server:before {
  content: "Сервер";
  right: 0.9em;
}

.schema {
  justify-content: space-between;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.schema .computer-mini {
  height: fit-content;
  width: 14em;
  margin-bottom: 1em;
  margin-top: 1em;
  position: relative;
}
.schema .computer-mini img {
  width: 100%;
  height: 100%;
}
.schema .computer-mini ul {
  position: absolute;
  top: 1.5em;
  list-style-type: none;
  left: 3.2em;
  font-size: 1.1em;
}
.schema .computer-mini ul li:nth-child(1), .schema .computer-mini ul li:nth-child(2) {
  background-color: rgba(50, 116, 197, 0.25);
}
.schema .computer-mini ul li:nth-child(4) {
  background-color: rgba(116, 197, 50, 0.2509803922);
}
.schema .computer {
  display: none;
  height: fit-content;
  width: 14em;
  margin-bottom: 1em;
  margin-top: 1em;
  position: relative;
}
.schema .computer img {
  width: 100%;
  height: 100%;
}
.schema .computer table {
  position: absolute;
  top: 0.8em;
  left: 1.85em;
}
.schema .computer td {
  border: 1px solid;
  text-align: center;
  padding: 0.3em 0.4em 0.4em;
}
.schema .computer tr:nth-child(2) td:nth-child(2) {
  background-color: rgba(116, 197, 50, 0.2509803922);
}
.schema .computer ul {
  list-style-type: none;
  position: absolute;
  top: 6em;
  line-height: 1.2em;
  left: 3.4em;
}
.schema .computer ul li:nth-child(1), .schema .computer ul li:nth-child(2) {
  background-color: rgba(50, 116, 197, 0.25);
}
.schema .arrow {
  width: 2em;
}
.schema .arrow svg {
  full: #008901;
}
.schema .arrow-1 {
  transform: rotate(-90deg);
}
.schema .arrow-2 {
  transform: rotate(90deg);
}
.schema .separator {
  width: 1em;
}
.schema .code-box-mini {
  position: relative;
  display: flex;
  font-size: 0.7em;
}
.schema .code-box-mini .code-arrow {
  position: absolute;
  width: 8em;
}
.schema .code-box-mini .code-arrow.code-arrow-1 {
  width: 1.5em;
  left: 50%;
  transform: translate(-100%);
  top: 3em;
}
.schema .code-box-mini .code-arrow.code-arrow-2 {
  width: 1.5em;
  left: 50%;
  transform: rotate(180deg);
  top: 3em;
}
.schema .code-box-mini .code-arrow.code-arrow-3 {
  width: 0.8em;
  left: 50%;
  transform: translate(-100%);
  top: 7.6em;
}
.schema .code-box-mini .code-arrow.code-arrow-4 {
  width: 0.8em;
  left: 50%;
  top: 7.6em;
  transform: rotate(180deg);
}
.schema .code-box {
  display: none;
  position: relative;
}
.schema .code-box .code-arrow {
  position: absolute;
  width: 8em;
}
.schema .code-box .code-arrow.code-arrow-1 {
  top: 13.8em;
  left: 15em;
}
.schema .code-box .code-arrow.code-arrow-2 {
  width: 8em;
  top: 20.6em;
  left: 15em;
  transform: rotate(10deg);
}
.schema .code-box .code-arrow.code-arrow-3 {
  top: 20.6em;
  left: 15em;
  transform: rotate(-10deg);
}
.schema .server {
  position: relative;
  margin-top: 2.5em;
}
.schema .server img {
  height: fit-content;
  width: 9em;
}
.schema .server:before {
  top: -1.6em;
  right: 1.2em;
  font-size: 1.7em;
  font-weight: 600;
  position: absolute;
  content: "Server";
}
@media (min-width: 400px) {
  .schema .code-box-mini {
    font-size: 0.8em;
  }
}
@media (min-width: 500px) {
  .schema .code-box-mini {
    font-size: 1em;
  }
}
@media (min-width: 768px) {
  .schema .computer-mini {
    display: none;
  }
  .schema .computer {
    display: initial;
  }
  .schema .code-box-mini {
    display: none;
  }
  .schema .code-box {
    display: flex;
  }
}
@media (min-width: 992px) {
  .schema {
    flex-direction: row;
    align-items: start;
  }
  .schema .arrow-1 {
    transform: rotate(-180deg);
    position: relative;
    top: 4.5em;
  }
  .schema .arrow-2 {
    position: absolute;
    left: 16.25em;
    transform: rotate(180deg);
    top: 26em;
  }
  .schema .server {
    position: absolute;
    top: 21em;
    left: 5.7em;
  }
}
@media (min-width: 1200px) {
  .schema {
    align-items: center;
  }
  .schema .arrow-1 {
    transform: rotate(-180deg);
    position: initial;
  }
  .schema .arrow-2 {
    position: initial;
    transform: rotate(0deg);
  }
  .schema .server {
    position: relative;
    top: unset;
    left: unset;
    margin: unset;
  }
}