
.btn-basis {
    color: #fff;
    background-color: #336fa1;
    border-color: #336fa1;
}

.btn-basis:active, .btn-basis:hover {
    color: #fff;
    background-color: #306897;
    border-color: #306897;
}

.medium_block_image {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 15px;
}


.flex-equal > * {
    flex: 1;
}




@media (min-width: 576px) {
    .bd-example {
        padding: 1.5rem;
        margin-right: 0;
        margin-left: 0;
        border-width: 1px;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }
}

@media (min-width: 768px) {
    .flex-md-equal > * {
        flex: 1;
    }
}


.bd-example {
    position: relative;
    padding: 0;
    margin: 1rem -0.75rem 0;
    border: solid #dee2e6;
    border-width: 1px;
    background: white;
    height: 20em;
    overflow-x: auto;
    overflow-y: hidden;
}




#home-table .bd-example table td:nth-child(2),
#home-table .bd-example table th:nth-child(2)
{
    display: none;
}

#home-table .bd-example table tr:nth-child(6)
{
    display: none;
}

@media (min-width: 1200px) {

    #home-table .bd-example table td, #home-table .bd-example table th {
        font-size: unset;
    }
    #home-table .bd-example table td:nth-child(2),
    #home-table .bd-example table th:nth-child(2)
    {
        display: table-cell;
    }

    #home-table .bd-example table tr:nth-child(6)
    {
        display: table-row;
    }
    #home-table .bd-example table tr:nth-child(7)
    {
        display: none;
    }
}
@media (min-width: 1200px) and (max-width: 1399px) {

    .bd-example .table > :not(caption) > * > * {
        padding: 0.4rem 0.4rem;
    }

}
@media (min-width: 1400px) {

    #home-table .bd-example table td, #home-table .bd-example table th {
        padding: 0.5rem 0.5rem;
        font-size: unset;
    }
}
//.table > :not(caption) > * > * {
//    padding: 0.4rem 0.4rem;
//}
